import { get } from "./http";

/*
Response data structure:
{
  "action": "string",
  "description": "string",
  "failReason": "string",
  "id": "string",
  "imageUrl": "string",
  "progress": "string",
  "status": "string"
}
*/

export default function checkMjTaskStatus(imageId, taskStatusOut) {
  get(`/Draw/task/${imageId}`).then(
    (response) => {
      console.log("Mj task status: ", response.status);
      console.log("Mj task progress: ", response.progress);

      taskStatusOut.status = response.status;
      taskStatusOut.progress = response.progress;

      switch (response.status) {
        case "SUCCESS":
          taskStatusOut.imageUrl = response.imageUrl;
          break;
        case "FAILURE":
          console.error(response.failReason);
          break;
        default:
          break;
      }
    },
    (error) => {
      console.error(error);
    }
  );
}
