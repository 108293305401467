<template>
  <div class="image-uploader">
    <input
      type="file"
      accept="image/*"
      @change="handleFileUpload"
      :id="inputId"
      class="hidden"
    />
    <label :for="inputId" class="cursor-pointer">
      <div
        class="w-10 h-10 border-2 border-dashed border-gray-300 rounded-lg flex items-center justify-center p-2"
      >
        <span v-if="!imageName" class="text-gray-500 truncate">{{
          label
        }}</span>
        <span v-else class="text-gray-700 truncate">{{ imageName }}</span>
      </div>
    </label>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      required: true,
    },
    inputId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      imageName: "",
    };
  },
  methods: {
    handleFileUpload(event) {
      const file = event.target.files[0];
      console.log(`InsightFace file (${this.inputId}):`, file);

      if (file) {
        this.imageName = file.name;
        const reader = new FileReader();
        reader.onload = (e) => {
          const encodedImage = e.target.result;
          //   console.log(
          //     `InsightFace encoded image (${this.inputId}):`,
          //     encodedImage
          //   );
          this.$emit("image-uploaded", encodedImage);
        };
        reader.readAsDataURL(file);
      }
    },
  },
};
</script>
