<template>
  <div>
    <div class="miya-draw imagine" v-if="actionModel === 'midjourney-imagine'">
      <div id="tttt"></div>
      <div class="headTitle">
        <p class="name">{{ $t("ai.t2") }}</p>
        <img class="img" src="@assets/images/ai/warn.svg" />
        <p class="redTip">
          <span>{{ $t("ai.tip2") }}</span>
        </p>
        <div class="configBtn" @click="clickFx()">
          <i class="iconfont icon-fx"></i>
          <span v-if="pageWidth > 800">{{ $t("ai.fx") }}</span>
        </div>
        <fx ref="fx"></fx>
      </div>
      <canvas id="downloadCanvas"></canvas>
      <div class="draw-panel" id="draw-panel">
        <div class="list" id="draw-list">
          <div
            class="item"
            :class="{ ai: item.from === 'ai' }"
            v-for="(item, idx) in dataList"
            :key="'draw' + idx"
          >
            <div class="avatar" v-if="item.from === 'ai'">
              <img class="aiImg" src="@assets/images/ai/ai.svg" />
            </div>
            <div style="flex: 1" v-if="item.from === 'user'"></div>
            <div class="text-box">
              <div class="text">
                <img
                  v-if="item.from === 'ai'"
                  class="arrow leftArrow"
                  src="@assets/images/ai/left.svg"
                />
                <p class="string" v-html="getChatText(item.text)"></p>
                <div class="aiImg" v-if="item.from === 'ai' && item.imgId">
                  <img
                    class="drawImg"
                    :id="'img' + idx"
                    :src="getImgUrl(item)"
                  />
                </div>
                <img
                  v-if="item.from === 'user'"
                  class="arrow rightArrow"
                  src="@assets/images/ai/right.svg"
                />
              </div>
              <div
                class="btnList"
                v-if="item.from === 'ai' && item.imgId && !item.isLoad"
                :class="'aiText' + idx"
                :data-clipboard-text="item.text"
              >
                <p class="btn" @click="clickDownload(idx)">
                  <i class="iconfont iconImg icon-download"></i
                  ><span>{{ $t("ai.download") }}</span>
                </p>
                <p class="btn" @click="clickSplit(idx)">
                  <i class="iconfont iconImg icon-split"></i
                  ><span>{{ $t("ai.split") }}</span>
                </p>
              </div>
            </div>
            <div class="avatar" v-if="item.from === 'user'">
              <img
                class="userImg"
                :src="require(`@assets/images/avatar/${avatarId}.svg`)"
              />
            </div>
          </div>
          <div class="item ai" v-show="showLoading">
            <div class="avatar">
              <img class="aiImg" src="@assets/images/ai/ai.svg" />
            </div>
            <div class="text-box">
              <div class="text">
                <img class="arrow leftArrow" src="@assets/images/ai/left.svg" />
                <div class="loadingBox">
                  <img class="loadingImg" src="@assets/images/ai/loading.gif" />
                  <p class="loadingText">{{ $t("ai.drawLoading") }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="phoneNew" v-if="pageWidth < 800">
        <el-button
          size="medium"
          type="primary"
          plain
          icon="el-icon-plus"
          @click="clickCreate"
          >{{ $t("ai.new") }}</el-button
        >
      </div>
      <div class="btn-stop" @click="clickStop()">{{ $t("ai.stopDraw") }}</div>
      <user-input
        @send="sendText"
        :disabled="inputDisabled"
        :placeholder="$t('ai.drawInputPlaceholder')"
        ref="userInput"
        v-if="actionModel === 'midjourney-imagine'"
      ></user-input>
      <el-dialog
        :visible.sync="dialogSplit"
        top="6vh"
        :close-on-click-modal="false"
        :show-close="false"
        :close-on-press-escape="false"
        :width="pageWidth < 800 ? '13rem' : '50%'"
      >
        <div class="canvasBox">
          <div class="closeBtn" @click="dialogSplit = false">
            <i class="el-icon-close"></i>
          </div>
          <img class="imgBg" id="imgBg1" />
          <img class="imgBg" id="imgBg2" />
          <img class="imgBg" id="imgBg3" />
          <img class="imgBg" id="imgBg4" />
          <canvas class="canvasBg" id="downloadCanvasBg1"></canvas>
          <canvas class="canvasBg" id="downloadCanvasBg2"></canvas>
          <canvas class="canvasBg" id="downloadCanvasBg3"></canvas>
          <canvas class="canvasBg" id="downloadCanvasBg4"></canvas>
          <div class="splitCanvas splitCanvas1">
            <canvas class="canvas" id="downloadCanvas1"></canvas>
            <div class="btnBox">
              <el-button
                class="downloadBtn"
                type="primary"
                @click="clickDownloadImg(1)"
                >{{ $t("ai.download") }}</el-button
              >
            </div>
          </div>
          <div class="splitCanvas splitCanvas1">
            <canvas class="canvas" id="downloadCanvas2"></canvas>
            <div class="btnBox">
              <el-button
                class="downloadBtn"
                type="primary"
                @click="clickDownloadImg(2)"
                >{{ $t("ai.download") }}</el-button
              >
            </div>
          </div>
          <div class="splitCanvas">
            <canvas class="canvas" id="downloadCanvas3"></canvas>
            <div class="btnBox">
              <el-button
                class="downloadBtn"
                type="primary"
                @click="clickDownloadImg(3)"
                >{{ $t("ai.download") }}</el-button
              >
            </div>
          </div>
          <div class="splitCanvas">
            <canvas class="canvas" id="downloadCanvas4"></canvas>
            <div class="btnBox">
              <el-button
                class="downloadBtn"
                type="primary"
                @click="clickDownloadImg(4)"
                >{{ $t("ai.download") }}</el-button
              >
            </div>
          </div>
        </div>
      </el-dialog>
    </div>
    <div class="blend" v-else-if="actionModel === 'midjourney-blend'">
      <Blend />
    </div>
    <div
      class="insightface-swap"
      v-else-if="actionModel === 'insightface-swap'"
    >
      <InsightFace />
    </div>
    <div v-else>
      <div>
        <p>Error: The action model is not supported.</p>
        <p>You should not see this error if you are operating correctly.</p>
        <p>Contact your administrator for more information.</p>
      </div>
    </div>
  </div>
</template>
<script>
import userInput from "../layout/userInput.vue";
import fx from "./fx.vue";
import Blend from "./blend/Blend.vue";
import InsightFace from "./insightface/InsightFace.vue";

import { mapGetters, mapActions } from "vuex";
import { get, isMobile } from "@assets/js/http.js";
import { actionModel } from "../../store/getters";
export default {
  data() {
    return {
      dataList: [],
      showLoading: false,
      imgId: "",
      isStop: false,
      disabled: false,
      dialogSplit: false,
    };
  },
  computed: {
    avatarId() {
      if (this.user && this.user.id) {
        return (
          (Number(
            this.user.id.substring(this.user.id.length - 3, this.user.id.length)
          ) %
            13) +
          1
        );
      }
      return "0";
    },
    inputDisabled() {
      if (this.historyDraw && !this.disabled && !this.showLoading) {
        return false;
      }
      return true;
    },
    ...mapGetters([
      "user",
      "actionOperation",
      "sessionId",
      "actionModel",
      "historyDraw",
      "createDraw",
    ]),
  },
  mounted() {
    this.initEvent();
    this.changeHeight();
    this.getDataList();
  },
  methods: {
    clickCreate() {
      // 新建
      let id = new Date().getTime();
      let historyList = JSON.parse(
        JSON.stringify(this.storage.historyList || { chat: [], draw: [] })
      );
      let list = historyList.draw;
      list.push({
        name: this.$t("ai.emptyDraw"),
        id: id,
        list: [{ from: "ai", text: this.$t("ai.defaultDraw"), isCheck: false }],
      });
      this.$store.commit("set_createDraw", true);
      this.$store.commit("set_historyDraw", id);
      this.saveStorage({ key: "historyList", val: historyList });
    },
    getDataList() {
      let historyList = JSON.parse(
        JSON.stringify(this.storage.historyList || { chat: [], draw: [] })
      );
      let list = historyList.draw;
      let str = JSON.stringify(historyList);
      if (str.length > 8000) {
        this.$message.warning(this.$t("operation.warn1"));
      }
      if (!this.historyDraw) {
        this.dataList = [{ from: "ai", text: this.$t("ai.defaultDraw") }];
      } else {
        let arr = list.find((val) => {
          return val.id === this.historyDraw;
        }).list;
        this.dataList = arr;
      }
      setTimeout(() => {
        this.checkImgLoad();
        this.scrollBottom();
      }, 20);
    },
    checkImgLoad() {
      this.$nextTick(() => {
        this.dataList.map((val, idx) => {
          if (val.from === "ai" && val.imgId) {
            val.isLoad = true;
            let img = document.getElementById(`img${idx}`);
            // img.onload = () => {
            //   val.isLoad = false;
            // };
          }
        });
      });
    },
    getChatText(text) {
      if (text.indexOf("\n") >= 0) {
        let arr = text.split("\n");
        return arr.join("<br/>");
      } else {
        return text;
      }
    },
    sendText(text) {
      this.showLoading = true;
      this.isStop = false;
      this.dataList.push({ from: "user", text: text });
      this.$refs.userInput.clear();
      this.scrollBottom();
      let data = {
        type: this.actionModel,
        sessionId: this.sessionId,
        prompt: text,
      };
      if (this.historyDraw) {
        let historyList = JSON.parse(
          JSON.stringify(this.storage.historyList || { chat: [], draw: [] })
        );
        let list = historyList.draw;
        let obj = list.find((val) => {
          return val.id === this.historyDraw;
        });
        if (obj) {
          obj.list = this.dataList;
          if (obj.name === this.$t("ai.emptyDraw")) {
            obj.name = text;
          }
        }
        this.saveStorage({ key: "historyList", val: historyList });
      }
      this.$post(`/Draw/imagine`, data).then((res) => {
        console.log(res);
        // TODO: some checks
        this.imgId = res.imageId;
        this.getImageStatus(res.imageId);
      });
    },
    getImageStatus(imgId, type) {
      console.log("getImageStatus", imgId);
      if (this.isStop) {
        this.isStop = false;
        return;
      }

      get(`/Draw/task/${imgId}`).then(
        (res) => {
          switch (res.status) {
            case "SUCCESS":
              this.handleSuccessStatus(imgId, res);
              break;
            case "IN_PROGRESS":
              this.handleInProgressStatus(imgId, res);
              break;
            case "FAILURE":
              this.handleFailureStatus();
              break;
            default:
              this.handleDefaultStatus(imgId, type);
          }
        },
        (error) => {
          this.handleErrorStatus();
        }
      );
    },
    handleSuccessStatus(imgId, taskData) {
      console.log("handleSuccessStatus", this.dataList);
      this.showLoading = false;
      let idx = this.dataList.findIndex((val) => val.imgId === imgId);
      console.log("idx", idx);
      if (idx >= 0) {
        let img = document.getElementById(`img${idx}`);
        img.setAttribute("src", taskData.imageUrl);
        console.log(img);
        // img.onload = () => {
        //   this.$set(this.dataList[idx], "isLoad", false);
        // };
      }
    },
    handleInProgressStatus(imgId) {
      console.log("handleInProgressStatus", this.dataList);
      if (this.showLoading) {
        this.showLoading = false;
        let obj = {
          from: "ai",
          text: "",
          imgId: imgId,
          isLoad: true,
        };
        this.dataList.push(obj);
        console.log("dataList updated!");
        this.saveDataList();
        this.$nextTick(() => {
          this.refreshImg(imgId);
        });
      } else if (!this.showLoading) {
        this.refreshImg(imgId);
      }
      this.scheduleNextCheck(imgId);
    },
    handleFailureStatus() {
      this.showLoading = false;
      this.$message.error(this.$t("ai.error3"));
    },
    handleDefaultStatus(imgId, type) {
      this.scheduleNextCheck(imgId, type);
    },
    handleErrorStatus() {
      this.showLoading = false;
      this.$message.error(this.$t("ai.error3"));
    },
    scheduleNextCheck(imgId, type) {
      setTimeout(() => {
        this.getImageStatus(imgId, type);
      }, 5000);
    },
    refreshImg(imgId) {
      let item = this.dataList.find((val) => {
        return val.imgId === imgId;
      });
      let idx = this.dataList.findIndex((val) => {
        return val.imgId === imgId;
      });
      if (idx >= 0) {
        let img = document.getElementById(`img${idx}`);
        img.setAttribute("src", this.getImgUrl(item));
      }
      this.scrollBottom();
    },
    saveDataList() {
      if (this.historyDraw) {
        let historyList = JSON.parse(
          JSON.stringify(this.storage.historyList || { chat: [], draw: [] })
        );
        let list = historyList.draw;
        let obj = list.find((val) => {
          return val.id === this.historyDraw;
        });
        if (obj) {
          obj.list = JSON.parse(JSON.stringify(this.dataList));
        }
        this.saveStorage({ key: "historyList", val: historyList });
      }
      this.getUserInfo();
    },
    clickFx() {
      this.$refs.fx.toggle();
    },
    clickStop() {
      this.isStop = true;
      this.showLoading = false;
    },
    createNewDraw() {
      this.dataList = [{ from: "ai", text: this.$t("ai.defaultDraw") }];
      this.$refs.userInput.clear();
      this.showLoading = false;
      this.$store.commit("set_createDraw", false);
    },
    clickDownload(idx) {
      let obj = this.dataList[idx];
      if (obj.from === "ai" && obj.imgId) {
        let img = document.getElementById(`img${idx}`);
        let width = img.naturalWidth;
        let height = img.naturalHeight;
        let canvas = document.getElementById("downloadCanvas");
        canvas.width = width;
        canvas.height = height;
        let ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0, width, height);
        if (isMobile()) {
          setTimeout(() => {
            let content = canvas.toDataURL("image/png");
            let blob = this.base64ToBlob(content);
            var a = document.createElement("a");
            a.href = URL.createObjectURL(blob);
            a.download = `${new Date().getTime()}.png`;
            a.click();
            return;
            var a = document.createElement("a");
            a.href = img1.src;
            a.download = `${new Date().getTime()}.png`;
            a.click();
          }, 20);
          return;
        }
        setTimeout(() => {
          var image = canvas.toDataURL("image/png");
          var a = document.createElement("a");
          a.href = image;
          a.download = `${new Date().getTime()}.png`;
          a.click();
          return image;
        }, 20);
      }
    },
    base64ToBlob(code) {
      let parts = code.split(";base64,");
      let contentType = parts[0].split(":")[1];
      let raw = window.atob(parts[1]);
      let rawLength = raw.length;
      let uInt8Array = new Uint8Array(rawLength);
      for (let i = 0; i < rawLength; ++i) {
        uInt8Array[i] = raw.charCodeAt(i);
      }
      return new Blob([uInt8Array], { type: contentType });
    },
    clickSplit(idx) {
      let obj = this.dataList[idx];
      if (obj.from === "ai" && obj.imgId) {
        let img = document.getElementById(`img${idx}`);
        this.dialogSplit = true;
        let width = img.naturalWidth;
        let height = img.naturalHeight;
        this.$nextTick(() => {
          if (obj.from === "ai" && obj.imgId) {
            let canvasW = document.documentElement.clientWidth * 0.25 - 28;
            if (this.pageWidth < 800) {
              let rem = document.documentElement.clientWidth / 20;
              canvasW = 12 * rem;
            }
            let w = canvasW;
            let h = (canvasW / (width / 2)) * (height / 2);
            this.drawImg(idx, 0, "downloadCanvas", w, h);
            this.drawImg(idx, 0, "downloadCanvasBg", width / 2, height / 2);
            this.drawImg(idx, 1, "downloadCanvas", w, h);
            this.drawImg(idx, 1, "downloadCanvasBg", width / 2, height / 2);
            this.drawImg(idx, 2, "downloadCanvas", w, h);
            this.drawImg(idx, 2, "downloadCanvasBg", width / 2, height / 2);
            this.drawImg(idx, 3, "downloadCanvas", w, h);
            this.drawImg(idx, 3, "downloadCanvasBg", width / 2, height / 2);
          }
        });
      }
    },
    async drawImg(idx, i, id, width, height) {
      let img = document.getElementById(`img${idx}`);
      let canvas = document.getElementById(`${id}${i + 1}`);
      canvas.width = width;
      canvas.height = height;
      let imgW = img.naturalWidth / 2;
      let imgH = img.naturalHeight / 2;
      let ctx = canvas.getContext("2d");
      let imgBg = document.getElementById(`imgBg${i + 1}`);
      imgBg.width = width;
      imgBg.height = height;
      await ctx.drawImage(
        img,
        (i % 2) * imgW,
        i < 2 ? 0 : imgH,
        imgW,
        imgH,
        0,
        0,
        width,
        height
      );
      await this.setImg(imgBg, canvas);
    },
    async setImg(imgBg, canvas) {
      imgBg.src = canvas.toDataURL("image/png");
    },
    clickDownloadImg(value) {
      let canvas = document.getElementById(`downloadCanvasBg${value}`);
      if (isMobile()) {
        setTimeout(() => {
          let content = canvas.toDataURL("image/png");
          let blob = this.base64ToBlob(content);
          var a = document.createElement("a");
          a.href = URL.createObjectURL(blob);
          a.download = `${new Date().getTime()}.png`;
          a.click();
          return;
        }, 20);
        return;
      }
      var image = canvas.toDataURL("image/png");
      var a = document.createElement("a");
      a.href = image;
      a.download = `${new Date().getTime()}.png`;
      a.click();
      return image;
    },
    getImgUrl(item) {
      return item.imageUrl;
    },
    getUserInfo() {
      this.$get(`/Check/GetInfo`).then((res) => {
        if (res.code === 200) {
          this.$store.commit("set_user", res.result);
        }
      });
    },
    scrollBottom() {
      this.$nextTick(() => {
        $("#draw-panel").scrollTop($("#draw-list").innerHeight() + 200);
      });
    },
    initEvent() {
      window.addEventListener("resize", this.changeHeight);
    },
    destroyWindowResize() {
      window.removeEventListener("resize", this.changeHeight);
    },
    changeHeight() {
      this.$nextTick(() => {
        let height = window.innerHeight - 214;
        if (this.pageWidth < 800) {
          height =
            window.innerHeight -
            (document.documentElement.clientWidth / 20) * 2.4 -
            144;
          $("#draw-panel").height(height);
          return;
        }
        $("#draw-panel").height(height > 614 ? height : 614);
      });
    },
  },
  watch: {
    createDraw() {
      if (this.createDraw) {
        this.createNewDraw();
      }
    },
    historyDraw() {
      this.getDataList();
    },
  },
  beforeDestroy() {
    this.destroyWindowResize();
  },
  components: { userInput, fx, Blend, InsightFace },
};
</script>
<style scoped lang="stylus">
.miya-draw
  position relative
  min-height 100vh
  height 100%
  #downloadCanvas
    position absolute
    top 0
    left 0
    display none
  .headTitle
    height 71px
    border-bottom 1px solid $c4
    padding 0 24px
    display flex
    .name
      color $c1
      font-weight 600
      font-size $f20
      margin-right 20px
      line-height 70px
    .img
      width 20px
      margin-right 6px
    .redTip
      color #FF3923
      margin-right 16px
      flex 1
      display flex
      flex-direction column
      justify-content center
      span
        font-size $f12
        line-height 14px
    .configBtn
      margin 16px 0
      border-radius 50px
      background-color #EBEBEB
      color $c2
      padding 0 26px
      cursor pointer
      line-height 36px
      font-size $f14
      .iconfont
        font-size $f14
        margin-right 6px
      &:hover
        background-color #FFF4E6
        color $c1
  .draw-panel
    background-color #F5F5F5
    overflow-y auto
    position relative
    .list
      width 80%
      padding 24px 0 32px 0
      margin 0 auto
      .item
        margin-bottom 24px
        display flex
        justify-content right
        .avatar
          width 64px
          .aiImg
            width 48px
            margin-right 16px
          .userImg
            margin-left 16px
            width 48px
            border-radius 50%
        .text-box
          max-width calc(100% - 128px)
          .text
            position relative
            padding 12px 24px
            background-color $c8
            color $c7
            border-radius 16px 0 16px 16px
            .string
              line-height 26px
              font-size $f15
              white-space wrap
              word-wrap break-word
            .arrow
              position absolute
              top 0
            .leftArrow
              left -8px
            .rightArrow
              right -8px
            .loadingBox
              width 300px
              background-color $c7
              position relative
              .loadingImg
                width 100%
              .loadingText
                text-align center
                position absolute
                width 100%
                bottom 20px
            .aiImg
              width 27vw
              min-width 300px
              .drawImg
                width 100%
          .btnList
            font-size $f14
            line-height 16px
            margin-top 8px
            display flex
            .btn
              color $c3
              display flex
              cursor pointer
              margin-right 40px
              .iconImg
                font-size $f18
                margin-right 6px
              &:hover
                color $c8
      .ai
        justify-content left
        .text-box
          .text
            background-color #EBEBEB
            color $c1
            border-radius 0 16px 16px 16px
  .btn-stop
    position absolute
    bottom 163px
    left 50%
    border-radius 50px
    padding 0 16px
    text-align center
    line-height 36px
    color $c7
    background-color #FF3923
    cursor pointer
    box-shadow: 0px 8px 16px 1px rgba(255,57,35,0.24);
    transform translate3d(-50%,0,0)
    &:hover
      background-color #FF2933
      box-shadow: 0px 8px 16px 1px rgba(255,57,35,0.34);
  .canvasBox
    display flex
    flex-wrap wrap
    justify-content space-between
    position relative
    .canvasBg, .imgBg
      position absolute
      top 0
      left 0
      display none
    .splitCanvas
      position relative
      width calc(25vw - 28px)
      .canvas
        width 100%
        height 100%
      .btnBox
        display none
        position absolute
        top 0
        left 0
        width 100%
        height 100%
        background-color rgba(0,0,0,0.4)
        .downloadBtn
          position absolute
          top 50%
          left 50%
          transform translate3d(-50%,-50%,0)
      &:hover
        .btnBox
          display block
    .splitCanvas1
      margin-bottom 8px
    .closeBtn
      position absolute
      top -60px
      right -60px
      color $c1
      font-size $f18
      cursor pointer
      border-radius 50%
      width 32px
      height 32px
      text-align center
      line-height 32px
      background-color rgba(255,255,255,0.6)
  >>>.el-dialog__header
    padding 0
  >>>.el-dialog__body
    padding 24px

@media screen and (max-width: 800px)
  .miya-draw
    min-height calc(100vh - 10rem)
    overflow-x hidden
    .phoneNew
      position absolute
      right 0.5rem
      bottom 163px
      z-index 2
    .headTitle
      height 0
      padding 0
      border-bottom none
      .name, .img
        display none
      .configBtn
        position absolute
        z-index 2
        top 0.5rem
        right 0.5rem
        margin 0
        padding 0 0.5rem
      .redTip
        z-index 2
        position absolute
        span
          white-space nowrap
          margin-left 100vw
          animation moveText 40s linear infinite
          position relative
    .draw-panel
      .list
        width 100%
        .item
          padding 12px 0.5rem
          margin-bottom 0.5rem
          .text-box
            left calc(0.5rem + 40px)
            top 12px
            .text
              padding 12px 0.8rem
              .loadingBox
                width 9rem
              .aiImg
                min-width 9rem
            .btnList
              display block
              .btn
                margin-right 0
                margin-bottom 0.3rem
    .canvasBox
      .splitCanvas
        width 100%
        margin-bottom 0.3rem
        &:last-child
          margin-bottom 0
      .closeBtn
        top -45px
        right -45px
    >>>.el-dialog__body
      padding 0.5rem
  @keyframes moveText
    0%
      left 0
    100%
      left -100%
</style>
