<template>
  <div class="insight-face p-2">
    <h1 class="font-bold">InsightFace Swap</h1>
    <div class="flex justify-center gap-2 p-2">
      <ImageUploader
        label="Upload Source"
        input-id="source-image"
        @image-uploaded="handleSourceImageUpload"
      />
      <ImageUploader
        label="Upload Target"
        input-id="target-image"
        @image-uploaded="handleTargetImageUpload"
      />
    </div>
    <div class="flex flex-col justify-center items-center space-y-2">
      <button
        @click="swapFaces"
        class="bg-yellow-400 hover:bg-yellow-600 text-white font-bold py-2 px-4 rounded"
        :class="!canSwap ? 'opacity-50 cursor-not-allowed' : ''"
      >
        Swap Faces
      </button>
      <div v-if="resultImageUrl" class="space-y-1">
        <img :src="resultImageUrl" alt="Swapped Face" class="w-10 h-auto" />
      </div>
    </div>
  </div>
</template>

<script>
import ImageUploader from "./ImageUploader.vue";
import { post } from "@/assets/js/http";
import checkMjTaskStatus from "@/assets/js/checkMjTaskStatus";

export default {
  components: {
    ImageUploader,
  },
  data() {
    return {
      sourceImage: null,
      targetImage: null,
      resultImageUrl: null,
      taskStatus: {
        status: null,
        progress: null,
        imageUrl: null,
      },
    };
  },
  computed: {
    canSwap() {
      return this.sourceImage && this.targetImage;
    },
  },
  methods: {
    handleSourceImageUpload(base64Image) {
      this.sourceImage = base64Image;
    },
    handleTargetImageUpload(base64Image) {
      this.targetImage = base64Image;
    },
    swapFaces() {
      if (!this.canSwap) return;

      post("/Draw/insightface/swap", {
        sourceBase64: this.sourceImage,
        targetBase64: this.targetImage,
      })
        .then((response) => {
          console.log("Submitted swapping faces task response:", response);
          this.checkTaskStatus(response.imageId);
        })
        .catch((error) => {
          console.error("Error submitting swapping faces task:", error);
        });
    },
    checkTaskStatus(imageId) {
      checkMjTaskStatus(imageId, this.taskStatus);
      this.pollTaskStatus(imageId);
    },
    pollTaskStatus(imageId) {
      const poll = setInterval(() => {
        if (this.taskStatus.status === "SUCCESS") {
          clearInterval(poll);
          this.resultImageUrl = this.taskStatus.imageUrl;
        } else if (this.taskStatus.status === "FAILURE") {
          clearInterval(poll);
          console.error("Task failed");
        } else {
          console.log("Polling task status...");
          checkMjTaskStatus(imageId, this.taskStatus);
        }
      }, 6000);
    },
  },
};
</script>
