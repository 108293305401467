<template>
  <div class="mt-1">
    <h2 class="font-semibold mb-1">Blend Result</h2>
    <img
      :src="imageUrl"
      alt="Blended Image"
      class="max-w-full h-auto rounded-lg shadow-lg"
    />
  </div>
</template>

<script>
export default {
  props: {
    imageUrl: {
      type: String,
      required: true,
    },
  },
};
</script>
