<template>
  <div class="p-1 flex flex-col justify-center items-center gap-1">
    <div class="w-full flex">
      <!-- upload input -->
      <div
        class="flex-grow flex items-center w-5/6 h-4 p-1 border-2 border-dashed border-yellow-500 rounded-l-sm hover:bg-yellow-500 hover:bg-opacity-30 cursor-pointer"
        @click="openFileInput"
      >
        <p>{{ $t("ai.userUpload") }}</p>
      </div>
      <!-- blend button -->
      <button
        @click="clickSend"
        class="w-1/6 h-4 rounded-r-sm text-white font-bold transition-colors duration-200"
        :class="buttonClass"
      >
        {{ buttonText }}
      </button>
      <input
        type="file"
        ref="fileInput"
        accept="image/*"
        multiple
        class="hidden"
        @change="handleFileUpload"
      />
    </div>
    <!-- file list -->
    <ul v-if="uploadedImagesNames.length > 0" class="w-full space-y-1">
      <li
        v-for="(name, index) in uploadedImagesNames"
        :key="index"
        class="bg-gray-100 p-1 rounded-sm"
      >
        {{ name }}
      </li>
    </ul>
  </div>
</template>

<script>
import checkMjTaskStatus from "@/assets/js/checkMjTaskStatus";

export default {
  data() {
    return {
      uploadedImagesNames: [],
      uploadedImagesEncoded: [],
      taskStatus: {
        status: "TO_SEND", // TO_SEND, NOT_START, IN_PROGRESS, SUCCESS, FAILURE
        progress: null,
        imageUrl: null,
      },
    };
  },
  computed: {
    buttonText() {
      switch (this.taskStatus.status) {
        case "TO_SEND":
          return "Blend";
        case "NOT_START":
          return "QUEUED";
        case "IN_PROGRESS":
          return `${this.taskStatus.progress}`;
        case "SUCCESS":
          return "SUCCESS";
        case "FAILURE":
          return "FAILURE";
        default:
          return "Blend";
      }
    },
    buttonClass() {
      if (this.uploadedImagesNames.length === 0) {
        return "bg-gray-300 cursor-not-allowed";
      }
      switch (this.taskStatus.status) {
        case "TO_SEND":
          return "bg-yellow-500 hover:bg-yellow-600 cursor-pointer";
        case "NOT_START":
        case "IN_PROGRESS":
          return "bg-yellow-800 cursor-wait";
        case "SUCCESS":
          return "bg-green-500 cursor-not-allowed";
        case "FAILURE":
          return "bg-red-500 cursor-not-allowed";
        default:
          return "bg-yellow-500 hover:bg-yellow-600 cursor-pointer";
      }
    },
  },
  methods: {
    openFileInput() {
      this.$refs.fileInput.click();
    },
    handleFileUpload(event) {
      // Reset the uploaded images
      this.uploadedImagesNames = [];
      this.uploadedImagesEncoded = [];

      const files = event.target.files;
      if (files.length === 0) return;

      Array.from(files).forEach((file) => {
        this.uploadedImagesNames.push(file.name);
        const reader = new FileReader();
        reader.onload = (e) => {
          this.uploadedImagesEncoded.push(e.target.result);
        };
        reader.readAsDataURL(file);
      });
      console.log("Images' names: ", this.uploadedImagesNames);
      console.log("Images encoded: ", this.uploadedImagesEncoded);

      event.target.value = "";
    },
    clickSend() {
      if (this.uploadedImagesNames.length === 0) return;

      this.taskStatus.status = "IN_PROGRESS";
      this.taskStatus.progress = 0;
      console.log(
        "Sending images to AI model: ",
        this.uploadedImagesNames,
        this.uploadedImagesEncoded
      );
      console.log("Waiting for AI model response...");
      this.$post(`/Draw/blend`, {
        base64Array: this.uploadedImagesEncoded,
        dimensions: "SQUARE",
      }).then((response) => {
        console.log("Response from AI model: ", response);
        this.checkTaskStatus(response.imageId);
      });
    },
    checkTaskStatus(imageId) {
      checkMjTaskStatus(imageId, this.taskStatus);
      this.pollTaskStatus(imageId);
    },
    pollTaskStatus(imageId) {
      const poll = setInterval(() => {
        if (this.taskStatus.status === "SUCCESS") {
          clearInterval(poll);
          console.log(
            "Image URL from `UserUpload` component: ",
            this.taskStatus.imageUrl
          );
          this.$emit("blend-complete", this.taskStatus.imageUrl);
        } else if (this.taskStatus.status === "FAILURE") {
          clearInterval(poll);
          console.error("Task failed");
        } else {
          console.log("Polling task status...");
          checkMjTaskStatus(imageId, this.taskStatus);
        }
      }, 6000);
    },
  },
};
</script>
