<template>
  <div class="p-2">
    <h1 class="font-bold">Blend Images</h1>
    <UserUpload @blend-complete="handleBlendComplete" />
    <BlendResult v-if="blendedImageUrl" :imageUrl="blendedImageUrl" />
  </div>
</template>

<script>
import UserUpload from "./UserUpload.vue";
import BlendResult from "./BlendResult.vue";

export default {
  components: {
    UserUpload,
    BlendResult,
  },
  data() {
    return {
      blendedImageUrl: null,
    };
  },
  methods: {
    handleBlendComplete(imageUrl) {
      this.blendedImageUrl = imageUrl;
    },
  },
};
</script>
